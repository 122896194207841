@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Graisse 500 (medium) */
@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Graisse 600 (semi bold) */
@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}


/* Graisse 800 (extra bold) */
@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../public/assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
