* {
	font-family: 'Montserrat';
}
body {
	width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
	box-sizing: border-box;
	background-size:contain;
	background: rgb(255,255,255);
	background: linear-gradient(128deg, rgba(255,255,255,1) 0%, rgba(220,220,220,1) 56%, rgba(197, 205, 255) 100%, rgba(197, 205, 255) 100vw);
}
body.dark {
	background: rgb(0,0,0);
	background: linear-gradient(128deg, rgba(0,0,0,1) 0%, rgba(1,8,46,1) 56%, rgba(130,21,71,1) 90%, rgba(154,35,92,1) 100%);
}

main {
	position: relative;
	z-index: 2;
	padding: 20px 60px;
	box-sizing: content-box;
	font-weight: 500;
	overflow-x: hidden;
}

@media (max-width: 650px) {
	main {
		padding: 10px 20px;
		text-align: center;
	}
}